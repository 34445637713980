<template>

	<div class="user">
		<h4>消息中心</h4>

		<div class="tab">
			<div :class="{'active':sign==1}" @click="chose(1)">订单信息</div>
			<div :class="{'active':sign==2}" @click="chose(2)">系统通知</div>

			<div class="bottom"></div>
		</div>
		<div class="coulist">
			<div class="couitem" v-for="(item,i) in list" :key="i" @click="messageRead(item.id)">
				<div class="c1">
					<img src="../../assets/user/order.png" alt="">
					<div class="dian" v-if="item.status_type_2==0"></div>
				</div>
				<div class="c2">
                    <div>
                        <p><strong>{{item.order_status | type}}</strong></p>
                        <p>{{item.create_time}}</p>
                    </div>
					<p>{{item.content}}
						<!-- <span>+5</span>。 <span>点击查看进度</span> -->
					</p>

				</div>
				<img src="../../assets/user/del.png" class="del" @click="del(item.id)">
				
			</div>

            
		</div>
	</div>

</template>

<script>
import {ordermsgListAPI,messagePushLogDeleteAPI,messageReadAPI} from "@/api/user"
export default {

  data () {
    return {
      sign: 1,
	  list:[]

    }
  },
  created () {
	this.sign= this.$route.query.id
	this.getlist()
	
  },
	watch:{
		$route(){
			this.sign= this.$route.query.id
			this.getlist()
			 
		},
	},
  filters:{
	  type(n){
		 if (n==1) {
			return "已成团"
		} else {
			return "未成团"
		}
	  }
  },
  methods: {
    chose (n) {
     this.sign=n
	this.$router.push({ path: '/user/ordermsg',query:{id:n} })
    },
	del(id) {
		this.$confirm('此操作将删除该消息, 是否继续?', '提示', {
		confirmButtonText: '确定',
		cancelButtonText: '取消',
		type: 'warning'
		}).then(() => {
			messagePushLogDeleteAPI({ id }).then(res => {
				this.getlist()
				this.$message("删除成功")
			})
			     
		});
	},
	getlist(){
		var data={
			type:this.sign
		}
		ordermsgListAPI(data).then(res=>{
			this.list=res.cateList
		})
	},
	messageRead(id){
		messageReadAPI({id}).then(res=>{
			this.getlist()
			this.$parent.messageCount()
		})
	}
  }
}
</script>

<style lang="scss" scoped="scoped">

	.user{
		padding: 5px 20px 20px;
		background: #ffffff;
		min-height: 500px;
		h4{
			font-size: 18px;
			font-weight: 500;
			color: #333333;
			font-weight: 500;
			line-height: 45px;
			text-indent: 10px;
		}

		.tab{
			display: flex;
			position: relative;
			div{
				cursor: pointer;
				width: 100px;
				height: 45px;
				font-size: 16px;
				font-weight: 500;
				line-height: 45px;
				text-align: center;
				color: #666666;
				border-bottom: 2px solid #cccccc;
				position: relative;
				z-index: 2;
			}
			.active{
				border-bottom: 2px solid #FF7C00;
				color: #ff7c00;
			}
			.bottom{
				width: 100%;
				height: 2px;
				border-bottom: 2px solid #cccccc;
				position: absolute;
				top: 43px;
				left: 0;
				z-index: 0;
			}

		}

		.coulist{
			.couitem{
			   width: 100%;
			   position: relative;
               display: flex;
               align-items: center;
               padding:10px 0;
			   border-radius: 10px;
			   padding-left: 10px;
				.del{
					position: absolute;
					top: 20px;
					right: 20px;
					width: 20px;
				}
           }
		   .couitem:hover {
			   background-color: #f0f0f0;
		   }
           .c1{
               margin-right: 20px;
			   position: relative;
			   .dian{
				   position: absolute;
				   top: 0;
				   left: 0;
				   background-color: red;
				   width: 10px;
				   height: 10px;
				   border-radius: 10px;
			   }
           }
           .c2{
               line-height: 30px;
               div{
                   display: flex;
                   p{
                       margin-right: 25px;
                       font-size: 12px;
                       color: #999999;
                       strong{
                           font-size: 16px;
                       }
                   }
               }
               >p{
                   span{
                       color: #FF7C00;
                   }
               }
           }

			

		}
	}

</style>
